import Swiper from 'swiper';
import SwiperCore, {Pagination} from 'swiper/core';
import '../../scss/imports/wp-blocks/block-slider.scss';

SwiperCore.use(Pagination);

const swiper = new Swiper('.swiper-container', {
	loop: true,
	autoHeight: true,
	pagination: {
		el: '.swiper-pagination',
		clickable: true,
	},
})